import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

function App() {
    const [state, setState] = useState(0)
    var regex = {
        establishment: /^[a-zA-Z0-9&|é@"#'^è!çà°^¨*€ù%µ£´`,?;.=+~ ]{2,20}$/,
    }
    var note = {}
    var content
    var button
    switch(state) {
        case 1:
            content = <div className='h-full w-full flex p-10'>
                <div className='w-full my-auto'>
                    <h1 className='text-3xl lg:text-4xl font-bold text-chocolate opacity-75 text-center'>Écris le nom de l'établissement</h1>
                    <input className='bg-chocolate text-2xl lg:text-4xl font-bold rounded-3xl text-vanilla placeholder:text-vanilla w-full p-2 px-4 my-4' type='text' id='establishment'></input>
                    <h2 className='text-1xl lg:text-2xl font-bold text-chocolate opacity-75 text-center' id='errorEstablishment'> </h2>
                </div>
            </div>
            button = <div className='p-10 pb-5 grid gap-5 grid-cols-2'>
                <div onClick={ previous } className="h-16 text-2xl lg:text-4xl font-bold rounded-3xl border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out flex">
                    <h1 className='m-auto text-chocolate'>Précédent</h1>
                </div>
                <div onClick={ next } className="h-16 text-2xl lg:text-4xl font-bold rounded-3xl border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out flex">
                    <h1 className='m-auto text-chocolate'>Suivant</h1>
                </div>
            </div>
            break
        case 2:
            note.establishment = document.getElementById('establishment').value
            console.log(note)
            content = <div className='h-full w-full flex p-10'>
                <div className='w-full my-auto'>
                    <h1 className='text-3xl lg:text-4xl font-bold text-chocolate opacity-75 text-center'>Coche les cases pour lesquelles tes attentes ont été satisfaites</h1>
                    <input type="checkbox" id='icecreamQuantity' name='icecreamQuantity'/><label for='icecreamQuantity'>Quantité de glace</label>
                    <input type="checkbox" id='icecreamQuality' name='icecreamQuality'/><label for='icecreamQuality'>Qualité de la glace</label>
                    <input type="checkbox" id='icecreamTemperature' name='icecreamTemperature'/><label for='icecreamTemperature'>Température de la glace</label>

                    <input type="checkbox" id='chocolateQuantity' name='chocolateQuantity'/><label for='chocolateQuantity'>Quantité du chocolat</label>
                    <input type="checkbox" id='chocolateQuality' name='chocolateQuality'/><label for='chocolateQuality'>Qualité du chocolat</label>
                    <input type="checkbox" id='chocolateTemperature' name='chocolateTemperature'/><label for='chocolateTemperature'>Température du chocolat</label>

                    <input type="checkbox" id='topping' name='topping'/><label for='topping'>Garniture</label>
                    <input type="checkbox" id='presentation' name='presentation'/><label for='presentation'>Présentation</label>
                    <input type="checkbox" id='practicality' name='practicality'/><label for='practicality'>Praticité</label>
                    <input type="checkbox" id='price' name='price'/><label for='price'>Prix</label>

                    
                </div>
            </div>
            button = <div className='p-10 pb-5 grid gap-5 grid-cols-2'>
                <div onClick={ previous } className="h-16 text-2xl lg:text-4xl font-bold rounded-3xl border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out flex">
                    <h1 className='m-auto text-chocolate'>Précédent</h1>
                </div>
                <div onClick={ next } className="h-16 text-2xl lg:text-4xl font-bold rounded-3xl border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out flex">
                    <h1 className='m-auto text-chocolate'>Suivant</h1>
                </div>
            </div>
            break
        case 3:
            content = <div className='h-full w-full flex p-10'>3</div>
            button = <div className='p-10 pb-5 grid gap-5 grid-cols-2'>
                <div onClick={ previous } className="h-16 text-2xl lg:text-4xl font-bold rounded-3xl border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out flex">
                    <h1 className='m-auto text-chocolate'>Précédent</h1>
                </div>
                <div onClick={ next } className="h-16 text-2xl lg:text-4xl font-bold rounded-3xl border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out flex">
                    <h1 className='m-auto text-chocolate'>Envoyer</h1>
                </div>
            </div>
            break
        case 4:
            content = <div className='h-full w-full flex p-10'>4</div>
            break
        default:
            content = <div className='h-full w-full flex p-10'>
                <div className='w-full my-auto'>
                    <h1 className='text-3xl lg:text-4xl font-bold text-chocolate opacity-75 text-center'>Ajouter une note consiste à coter une dame blanche et de la publier sur <b>DameBlanche.fans</b> afin de concevoir le classement des meilleures dames blanches.</h1>
                    <h2 className='text-1xl lg:text-2xl font-bold text-chocolate opacity-75 text-center'>Note: ceci est une version beta et n'intègre pas de fonctionnalité en ligne</h2>
                </div>
            </div>
            button = <div className='p-10 pb-5'>
                <div onClick={ next } className="h-16 w-full text-2xl lg:text-4xl font-bold rounded-3xl border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out flex">
                    <h1 className='m-auto text-chocolate'>Commencer</h1>
                </div>
            </div>
            break
    }

    function next() {
        if(state === 1) {
            let establishment = document.getElementById('establishment').value
            if(!regex.establishment.test(establishment)) {
                document.getElementById('errorEstablishment').innerText = 'Le nom de l\'établissement est incorrect (entre 2 et 20 caractères)'
                return
            }
        }
        setState(state + 1)
    }

    function previous() {
        setState(state - 1)
    }

    return (
        <div className="bg-[url('main_background.jpg')] bg-center bg-cover min-h-screen h-screen flex flex-col select-none">
            <div className='h-1/4 flex'>
                <h1 className='m-auto text-6xl font-bold text-chocolate opacity-75'>Ajouter</h1>
            </div>
            { content }
            { button }
            <div className='p-10 pt-0'>
                <Link to="/" className='h-16 text-3xl lg:text-5xl font-bold rounded-3xl border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out flex justify-between px-10'>
                    <FaArrowLeft className='my-auto h-10 w-10 fill-chocolate' size="2.5rem"/>
                    <h1 className='my-auto text-chocolate text-2xl lg:text-4xl'>Retour</h1>
                </Link>
            </div>
        </div>
    )
}

export default App;