import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Logo from './logo.svg';
import { FaListUl, FaPlus, FaUserAlt } from "react-icons/fa";

function App() {
  const [cookies, setCookie] = useCookies(['name'])

  const [showWelcome, setShowWelcome] = useState(false)

  function start() {
    setCookie('tutorial', true, { path: '/' })
    setShowWelcome(false)
  }

  useEffect(() => {
    if(cookies.tutorial === 'true') {
      setShowWelcome(false)
    } else {
      setShowWelcome(true)
    } 
  }, [setShowWelcome, cookies])

  return (
    showWelcome ?(
      <div className="bg-[url('welcome_background.jpg')] bg-center bg-cover min-h-screen h-screen flex flex-col-reverse select-none">
        <div className='p-10 flex flex-col sm:h-1/2 lg:h-3/5 xl:h-2/5'>
          <h1 className='text-chocolate text-center text-4xl font-extrabold pb-10'>Déguste et note ta meilleure <span className='underline'>dame blanche</span>.</h1>
          <button className='flex' onClick={start}>
            <h1 className='text-vanilla text-center p-4 text-2xl rounded-full font-bold bg-chocolate mx-auto'>
              Commencer la dégustation
            </h1>
          </button>
        </div>
        <div className='h-full flex'>
          <img src={Logo} className='w-1/2 max-h-1/2 max-w-sm m-auto' alt='Logo'/>
        </div>
      </div>
    ) : (
      <div className="bg-[url('main_background.jpg')] bg-center bg-cover min-h-screen h-screen flex flex-col select-none">
        <div className='h-1/4 flex'>
          <h1 className='m-auto text-5xl lg:text-6xl font-bold text-chocolate opacity-75'>Dame Blanche</h1>
        </div>
        <div className='h-3/4 flex flex-col justify-evenly'>
          <div className='h-1/3 p-10 flex'>
            <Link to='/add' className='m-auto text-3xl lg:text-5xl font-bold rounded-3xl h-full w-full flex border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out justify-between px-10'>
              <FaPlus className='my-auto fill-chocolate'/>
              <h1 className='my-auto text-chocolate'>Ajouter</h1>
            </Link>
          </div>
          <div className='h-1/3 p-10 flex'>
            <Link to='/view' className='m-auto text-3xl lg:text-5xl font-bold rounded-3xl h-full w-full flex border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out justify-between px-10'>
              <FaListUl className='my-auto fill-chocolate'/>
              <h1 className='my-auto text-chocolate'>Consulter</h1>
            </Link>
          </div>
          <div className='h-1/3 p-10 flex'>
            <Link to='/account' className='m-auto text-3xl lg:text-5xl font-bold rounded-3xl h-full w-full flex border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out justify-between px-10'>
              <FaUserAlt className='my-auto fill-chocolate'/>
              <h1 className='my-auto text-chocolate'>Compte</h1>
            </Link>
          </div>
        </div>
    </div>
    )
  )
}

export default App;