import React from 'react';
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

function App() {
  return (
    <div className="bg-[url('main_background.jpg')] bg-center bg-cover min-h-screen h-screen flex flex-col">
        <div className='h-1/4 flex'>
            <h1 className='m-auto text-6xl font-bold text-chocolate opacity-75'>Consulter</h1>
        </div>
        <div className='h-full w-full'>
            <form>
                <label for="establishment">EEEE</label>
                <input id='establishment' name='establishment'></input>
            </form>
        </div>
        <div className='p-10'>
            <Link to="/" className='h-16 text-3xl lg:text-5xl font-bold rounded-3xl border-8 border-chocolate opacity-75 hover:opacity-100 transition duration-100 ease-in-out flex justify-between px-10'>
                <FaArrowLeft className='my-auto h-10 w-10 fill-chocolate' size="2.5rem"/>
                <h1 className='my-auto text-chocolate'>Retour</h1>
            </Link>
        </div>
    </div>
  )
}

export default App;